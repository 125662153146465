import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'login', 
    pathMatch: 'full'
  },
  { 
    path: 'login', 
    component: LoginComponent 
  },
  { 
    path: 'home',
    loadChildren: () => 
      import('./dashboard/dashboard.module')
      .then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'interns',
    loadChildren: () => 
      import('./interns/interns.module')
      .then(m => m.InternsModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'time-off',
    loadChildren: () => 
      import('./time-off/time-off.module')
      .then(m => m.TimeOffModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'dailystatus',
    loadChildren: () => 
      import('./daily-status/daily-status.module')
      .then(m => m.DailyStatusModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'approvals',
    loadChildren: () => 
      import('./approvals/approvals.module')
      .then(m => m.ApprovalsModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'assessments',
    loadChildren: () => 
      import('./assessment/assessment.module')
      .then(m => m.AssessmentModule),
    canActivate: [AuthGuard]
  },
  { 
    path: 'announcements',
    loadChildren: () => 
      import('./announcements/announcements.module')
      .then(m => m.AnnouncementsModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
