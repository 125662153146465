<mat-card class="widget-card">
    <mat-card-header
        class="card-header-wg">
        <mat-card-title>{{ widgetTitle }}</mat-card-title>
    </mat-card-header>
    <mat-progress-bar 
        mode="indeterminate"
        *ngIf="isLoading$ | async"></mat-progress-bar>
        
    <mat-card-content class="widget-content" 
        [ngClass]="{ grayscaleblur: isLoading$ | async }">
        {{ widgetContent }}
    </mat-card-content>

    <mat-card-actions
        *ngIf="widgetViewMoreLink"
        [ngClass]="{ grayscaleblur: isLoading$ | async }">
        <a style="cursor: pointer" (click)="onClick()">View More</a>
    </mat-card-actions>
    
</mat-card>