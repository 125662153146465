<div class="header">
    <a routerLink="/">
        <img alt="logo" class="main-logo" src="assets/interns-portal-logo.svg" />
    </a>

    <div class="user-info" *ngIf="isUserLoggedIn">
        <span>{{ loggedInUser }}</span>
        <a 
            mat-stroked-button 
            color="primary" 
            class="logout-button"
            (click)="onLogout()">
            Logout
        </a>
    </div>
</div>