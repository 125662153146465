import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnnouncementsService } from '../announcements-service.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@angular/cdk/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnnouncementsListService } from 'src/app/shared/components/announcements-list/announcements-list.service';
import { Announcement } from 'src/app/shared/services/backend.service';

@Component({
  selector: 'app-add-update-announcement',
  templateUrl: './add-update-announcement.component.html',
  styleUrls: ['./add-update-announcement.component.scss']
})
export class AddUpdateAnnouncementComponent {
  isSubmitted = false;

  announcementForm = new FormGroup({
    description: new FormControl('', Validators.required),
    expiryInDays: new FormControl(7, Validators.required)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: announcementsViewModel,
    private dialogRef: DialogRef,
    private announcementsService: AnnouncementsService,
    private announcementsListService: AnnouncementsListService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    if(this.data.mode === 'Update') {
      this.assignAnnouncementDetailsToForm();
    }
  }
  
  onSubmit() {
    if(!this.announcementForm.valid) {
      return;
    }

    this.isSubmitted = true;

    const body = this.getAnnouncementSubmitBody();

    if(this.data.mode === 'Add') {
      this.announcementsService.addAnnouncement(body).subscribe({
        next: (data: any) => { 
          this.snackBar.open(data['message'], 'Dismiss', {
            duration: 5000,
          });
          
          this.announcementsListService.getAnnouncementList();

          this.dialogRef.close();
        },
        error: (error) => {
          if(error.status == 400) {
            this.snackBar.open('Invalid Request - ' + error.error['message'], 'Dismiss', {
              duration: 5000,
            });
          }
          else if(error.status == 401 || error.status == 403){
            this.snackBar.open('Unauthorized. You are not allowed for this action.', 'Dismiss', {
              duration: 5000,
            });
          }
          else {
            this.snackBar.open('Internal Error. Please contact the admin.', 'Dismiss', {
              duration: 5000,
            });
          }
          
          this.isSubmitted = false;
        }
      });
    }

    else if(this.data.mode === 'Update') {
      let id = this.data.announcement?.TYPE ?? "";
      const body = {
        ANNOUNCEMENT: this.announcementForm.value['description']
      }

      this.announcementsService.updateAnnouncement(id, body).subscribe({
        next: (data: any) => { 
          this.snackBar.open(data['message'], 'Dismiss', {
            duration: 5000,
          });
          
          this.announcementsListService.getAnnouncementList();

          this.dialogRef.close();
        },
        error: (error) => {
          if(error.status == 400) {
            this.snackBar.open('Invalid Request - ' + error.error['message'], 'Dismiss', {
              duration: 5000,
            });
          }
          else if(error.status == 401 || error.status == 403){
            this.snackBar.open('Unauthorized. You are not allowed for this action.', 'Dismiss', {
              duration: 5000,
            });
          }
          else {
            this.snackBar.open('Internal Error. Please contact the admin.', 'Dismiss', {
              duration: 5000,
            });
          }
          
          this.isSubmitted = false;
        }
      });
    }
  }

  getAnnouncementSubmitBody() {
    return {
      ANNOUNCEMENT: this.announcementForm.value['description'],
      EXPIRY_DAYS: this.announcementForm.value['expiryInDays']?.toString()
    }
  }

  assignAnnouncementDetailsToForm() {
    this.announcementForm.patchValue({
      description: this.data.announcement?.ANNOUNCEMENT,
      expiryInDays: +(this.data.announcement?.EXPIRY_DAYS ?? "7")
    });

    this.announcementForm.controls["expiryInDays"].disable();
  }
}

export interface announcementsViewModel {
  mode: string,
  announcement: Announcement | null
}