import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, concatMap, map } from "rxjs";
import { Router } from "@angular/router";

import { environment } from "src/environments/environment";
import { LoginRequest, LoginSsoRequest } from "../models/external/login-request.model";
import { LoginResponse } from "../models/external/login-response.model";
import { UserSession } from "../models/user-session.model";
import jwt_decode from "jwt-decode";
import { JwtToken } from "../models/jwt-token.model";
import { StaticData } from "../models/static-data.model";
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { RedirectRequest } from "@azure/msal-browser";

export const InterceptorSkip = 'X-Skip-Interceptor';
export const TokenSessionStorageKey = 'wgip-token-session'
export const UserSessionStorageKey = 'wgip-user-session';
export const StaticSessionStorageKey = 'wgip-static-data';

@Injectable({ providedIn: 'root' })
export class AuthService {
    isUserLoggedIn$ = new BehaviorSubject<boolean>(false);
    InterceptorSkipHeader = new HttpHeaders({
        'X-Skip-Interceptor': ''
    });

    sessionItems = [
        TokenSessionStorageKey,
        UserSessionStorageKey, 
        StaticSessionStorageKey
    ];

    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
        private msalService: MsalService
    ) {
        this.isUserLoggedIn$.next(this.isLoggedIn());
    }

    login(loginData: LoginRequest) {
        return this.http
        .post<LoginResponse>(
            environment.LOGIN_ENDPOINT,
            loginData,
            { headers: this.InterceptorSkipHeader });
    }

    loginSso() {
        if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        
        } else {
            this.msalService.loginRedirect();
        }
    }

    loginUsingSso(loginData: LoginSsoRequest) {
        return this.http
            .post<any>(
                environment.LOGIN_SSO_ENDPOINT,
                loginData,
                { headers: this.InterceptorSkipHeader });
    }

    logout(): void {
        sessionStorage.clear();
        this.isUserLoggedIn$.next(false);
        this.router.navigate(['/login']);
    }

    getUserSessionData(value: keyof UserSession): string {
        const session: UserSession = JSON.parse(sessionStorage.getItem(UserSessionStorageKey)!);
        return session[value];
    }

    getToken(): string {
        return sessionStorage.getItem(TokenSessionStorageKey)!;
    }

    getTokenData(value: keyof JwtToken): string {
        const jwt: string = this.getToken();
        const decodedJwt: any = jwt_decode(jwt);
        return decodedJwt[value];
    }

    getStaticData(value: keyof StaticData): any {
        const staticSessionData: StaticData = JSON.parse(sessionStorage.getItem(StaticSessionStorageKey)!);
        return staticSessionData[value];
    }

    checkPermission(permissionList: string[], feature: string): boolean {
        
        if(permissionList !== undefined && permissionList.length == 0){
            return false;
        }
        
        return permissionList.includes(feature);
    }

    isLoggedIn(): boolean {
        const session: UserSession = JSON.parse(sessionStorage.getItem(UserSessionStorageKey)!);
        
        if (session === null) {
            return false;
        }

        const expiry: any = +this.getTokenData('exp');
        const timeExpired = new Date().getTime() > expiry * 1000;

        return !timeExpired;
    }
}