import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendService } from 'src/app/shared/services/backend.service';
import { AnnouncementsService } from '../announcements-service.service';
import { AnnouncementsListService } from 'src/app/shared/components/announcements-list/announcements-list.service';

@Component({
  selector: 'app-delete-announcement-dialog',
  templateUrl: './delete-announcement-dialog.component.html',
  styleUrls: ['./delete-announcement-dialog.component.scss']
})
export class DeleteAnnouncementDialogComponent {
  loading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private announcementsService: AnnouncementsService,
    private announcementsListService: AnnouncementsListService,
    private dialogRef: MatDialogRef<DeleteAnnouncementDialogComponent>,
    private snackBar: MatSnackBar
  ) {}

  deleteAnnouncement() {
    this.loading = true;

    this.announcementsService.deleteAnnouncement(this.data.id)
    .subscribe({
      next: (data: any) => {
        this.snackBar.open(data['message'], 'Dismiss', {
          duration: 5000,
        });

        this.announcementsListService.getAnnouncementList();

        this.dialogRef.close();
      },
      error: (error) => {
        this.loading = false;
        if(error.status == 200){
          this.snackBar.open(error.error['message'], 'Dismiss', {
            duration: 5000,
          });
        }
        else if(error.status == 400) {
          this.snackBar.open('Invalid Request - ' + error.error['message'], 'Dismiss', {
            duration: 5000,
          });
        }
        else if(error.status == 401 || error.status == 403){
          this.snackBar.open('Unauthorized. You are not allowed for this action.', 'Dismiss', {
            duration: 5000,
          });
        }
        else {
          this.snackBar.open('Internal Error. Please contact the admin.', 'Dismiss', {
            duration: 5000,
          });
        }
      }
    });
  }
}