import { Injectable } from '@angular/core';
import { Announcement, BackendService } from '../../services/backend.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsListService {
  isLoading$ = new BehaviorSubject<boolean>(true);
  announcementList = new BehaviorSubject<Announcement[]>([]);

  constructor(
    private backendService: BackendService
  ) {}

  getAnnouncementList() {
    this.isLoading$.next(true);

    this.backendService.getAnnouncementList().subscribe({
      next: (list: Announcement[]) => {
        this.isLoading$.next(false);
        this.announcementList.next(list);
      }
    });
  }
}
