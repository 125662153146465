export const environment = {
    production: false,

    BASE_API: "https://dev.api.interns.wginb.com",
    LOGIN_ENDPOINT: "https://dev.api.interns.wginb.com/wgiplogin/login",
    LOGIN_SSO_ENDPOINT: "https://dev.api.interns.wginb.com/wgiplogin/ssologin",


    INTERNS_LIST_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern/list/{year}/{batch}",
    ADD_INTERN_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern",
    GET_INTERN_DETAILS_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}",
    UPDATE_INTERN_DETAILS_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}",
    DELETE_INTERN_DETAILS_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}",
    INTERN_TIMEOFF_SUMMARY_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/timeoff/list",
    INTERN_TIMEOFF_DETAILS_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/timeoff/{timeoffId}",
    INTERN_SINGLE_TIMEOFF_DETAIL: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/timeoff/{timeOffId}",
    INTERN_ADD_TIMEOFF: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/timeoff",
    INTERN_CANCEL_TIMEOFF: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/timeoff/{timeOffId}",
    DAILY_STATUS_LIST: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/dailystatus/list",
    ADD_DAILY_STATUS: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/dailystatus",
    APPROVALS_LIST: "https://dev.api.interns.wginb.com/wgipapis/approval/list",
    APPROVALS_ACTION: "https://dev.api.interns.wginb.com/wgipapis/approval/{approvalId}",
    ASSESSMENT_LIST: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/assessment/list",
    ASSESSMENT_DETAILS: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/assessment/{assessmentId}",
    ADD_ASSESSMENT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/assessment",
    DELETE_ASSESSMENT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/assessment/{assessmentId}",
    CREDIT_TIMEOFF_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/intern/{email}/timeoff/credit",
    RESET_PASSWORD: "https://dev.portal.reset.wginb.com",
    DASHBOARD_ENDPOINT: "https://dev.api.interns.wginb.com/wgipapis/dashboard",
    ADD_ANNOUNCEMENT: "https://dev.api.interns.wginb.com/wgipapis/announcement",
    LIST_ANNOUNCEMENT: "https://dev.api.interns.wginb.com/wgipapis/announcement/list",
    UPDATE_ANNOUNCEMENT: "https://dev.api.interns.wginb.com/wgipapis/announcement/{announcementId}",
    DELETE_ANNOUNCEMENT: "https://dev.api.interns.wginb.com/wgipapis/announcement/{announcementId}",

    MSAL_CONFIG: {
        clientId: "f529d809-fdd8-4726-9659-2fc6c6f8f51d",
        authority: "https://login.microsoftonline.com/2563c132-88f5-466f-bbb2-e83153b3c808",
        redirectUri: "https://dev.portal.interns.wginb.com",
        postLogoutRedirectUri: 'https://dev.portal.interns.wginb.com'
    }
};
