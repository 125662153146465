<app-header></app-header>
<app-navigation
    *ngIf="isUserLoggedIn$ | async"></app-navigation>

<mat-progress-bar 
    mode="indeterminate"
    *ngIf="isNavigating$ | async"
    >
</mat-progress-bar>

<div class="container">
    <ng-content></ng-content>
</div>