import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { WidgetComponent } from './components/widget/widget.component';
import { CheckPermissionsDirective } from './directives/check-permissions.directive';
import { AnnouncementsListComponent } from './components/announcements-list/announcements-list.component';

const angularMaterialModules: any = [
    CommonModule,
    MatButtonModule,
    MatBadgeModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatIconModule,
    MatTableModule,
    MatDividerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCardModule,
    MatMenuModule,
    MatTooltipModule
]

@NgModule({
    imports: [...angularMaterialModules],
    exports: [
        ...angularMaterialModules, 
        WidgetComponent,
        CheckPermissionsDirective,
        AnnouncementsListComponent
    ],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} }
    ],
    declarations: [
        WidgetComponent,
        CheckPermissionsDirective,
        AnnouncementsListComponent
    ],
})
export class SharedModule { }