import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {
  @Input() widgetTitle: string = '';
  @Input() widgetContent: number = 0;
  @Input() widgetViewMoreLink: string | null = '';
  @Input() isLoading$!: BehaviorSubject<boolean>;

  constructor(
    private router: Router
  ) {}

  onClick() {
    this.router.navigate([this.widgetViewMoreLink])
  }
}
