<mat-card
    style="margin-top: 15px;"
>
    <mat-card-header
        class="card-header-wg"
    >
        <mat-card-title>Announcements</mat-card-title>
    </mat-card-header>

    <mat-progress-bar 
        mode="indeterminate"
        *ngIf="isLoading$ | async"
    ></mat-progress-bar>

    <mat-card-content 
        class="announcement-content" 
        [ngStyle]="!showActions ? {'max-height' : '350px'} : {}"
        [ngClass]="{ grayscaleblur: isLoading$ | async }"
    >
        <span
            *ngIf="!(isLoading$ | async) && announcementList.length === 0"
        >No Announcements</span> 

        <div
            class="announcement-section"
            *ngFor="let announcement of announcementList"
        >
            <div class="announcement-header">
                <span>
                    <b>{{ announcement.ANNOUNCEMENT_TIME | date }}</b>
                    <p 
                        *ngIf="showActions"
                        class="footnote"
                        appCheckPermissions="announcements_update"
                    >Expiry: {{ announcement.EXPIRY_DAYS }} day(s) from announcement date</p>
                </span>
                <div 
                    *ngIf="showActions"
                    class="actions"
                >
                    <button 
                        mat-icon-button
                        color="primary"
                        appCheckPermissions="announcements_update"
                        (click)="editAnnouncement(announcement)"
                    ><mat-icon>edit</mat-icon>
                    </button>
                    <button 
                        mat-icon-button
                        color="warn"
                        appCheckPermissions="announcements_delete"
                        (click)="deleteAnnouncement(announcement)"
                    ><mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        
            <p [innerText]="announcement.ANNOUNCEMENT"></p>
            <mat-divider></mat-divider>
        </div>
    </mat-card-content>
</mat-card>