<form 
    [formGroup]="announcementForm" 
    (submit)="onSubmit()"
>
    <h2 mat-dialog-title>{{ data.mode }} Announcement</h2>
    <mat-dialog-content>
        <mat-form-field 
            style="margin-top: 10px"
            appearance="outline" 
            class="input-full-width"
        >
            <mat-label>Expiry in Days (Max: 14)</mat-label>
            <input matInput formControlName="expiryInDays" type="number" min="1" max="14">
            <mat-error
                *ngIf="announcementForm.invalid && (announcementForm.dirty || announcementForm.touched)">
                Expiry is invalid
            </mat-error>
        </mat-form-field>

        <mat-form-field 
            appearance="outline"
            class="input-full-width">
            <mat-label>Description</mat-label>
            <textarea 
                rows="7"
                matInput 
                formControlName="description" 
                maxlength="500"
            ></textarea>
            <mat-error 
                *ngIf="announcementForm.invalid && (announcementForm.dirty || announcementForm.touched)">
                Description is required
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button 
            appCheckPermissions="announcements_add"
            type="submit"
            mat-raised-button 
            color="primary" 
            [disabled]="announcementForm.invalid || isSubmitted"
        >
            <span *ngIf="!isSubmitted">{{ data.mode }}</span>
            <span *ngIf="isSubmitted">
                <mat-spinner [diameter]="20"></mat-spinner>
            </span>
        </button>

        <button 
            mat-button 
            mat-dialog-close
            [disabled]="isSubmitted"
            >Close</button>
    </mat-dialog-actions>
</form>