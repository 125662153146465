import { Directive, ElementRef, Input } from "@angular/core";
import { AuthService } from "../services/auth.service";

@Directive({
  selector: '[appCheckPermissions]',
})
export class CheckPermissionsDirective {
  
  constructor(
    private authService: AuthService,
    private elementRef: ElementRef
  ) {}
    
  @Input() set appCheckPermissions(featureName: string) {

    if (this.authService.checkPermission(this.authService.getStaticData('UI'), featureName ?? '')) {
      this.elementRef.nativeElement.classList.remove('hidden-property');
    } else {
      this.elementRef.nativeElement.classList.add('hidden-property')
    }
  }
}