import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddUpdateAnnouncementComponent } from './add-update-announcement/add-update-announcement.component';
import { Announcement, BackendService } from '../shared/services/backend.service';
import { DeleteAnnouncementDialogComponent } from './delete-announcement-dialog/delete-announcement-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(
    private dialog: MatDialog,
    private backendService: BackendService
  ) {}

  openAnnouncementDialog(mode: string = "Add", announcement: Announcement | null = null) {
    this.dialog.open(AddUpdateAnnouncementComponent, {
      width: '650px',
      data: {
        mode,
        announcement
      }
    })
  }

  addAnnouncement(body: any) {
    return this.backendService.addAnnouncement(body);
  }

  updateAnnouncement(id: string, body: any) {
    return this.backendService.updateAnnouncement(id, body);
  }

  deleteConfirmationDialog(id: string) {
    this.dialog.open(DeleteAnnouncementDialogComponent, {
      data: {
        id
      }
    });
  }

  deleteAnnouncement(id: string) {
    return this.backendService.deleteAnnouncement(id);
  }
}
