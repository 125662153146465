import { Component, Input } from '@angular/core';
import { AnnouncementsListService } from './announcements-list.service';
import { Announcement } from '../../services/backend.service';
import { BehaviorSubject } from 'rxjs';
import { AnnouncementsService } from 'src/app/announcements/announcements-service.service';

@Component({
  selector: 'app-announcements-list',
  templateUrl: './announcements-list.component.html',
  styleUrls: ['./announcements-list.component.scss']
})
export class AnnouncementsListComponent {
  @Input() showActions!: boolean | null;
  announcementList: Announcement[] = [];
  isLoading$!: BehaviorSubject<boolean>;

  constructor(
    private announcementsListService: AnnouncementsListService,
    private announcementsService: AnnouncementsService
  ) {}

  ngOnInit() {
    this.isLoading$ = this.announcementsListService.isLoading$;
    this.announcementsListService.announcementList.subscribe({
      next: (list: Announcement[]) => this.announcementList = list
    });

    this.announcementsListService.getAnnouncementList();
  }

  editAnnouncement(announcement: Announcement) {
    this.announcementsService.openAnnouncementDialog('Update', announcement);
  }

  deleteAnnouncement(announcement: Announcement) {
    this.announcementsService.deleteConfirmationDialog(announcement.TYPE);
  }
}
