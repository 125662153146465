import { Component } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  screens!: string[];

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.screens = this.authService.getStaticData('SCREENS');
  }
}
