<div class="custom-spinner" *ngIf="loginLoader === 'true'? true: false || loginFormSubmitted">
    <mat-spinner class="spinner"></mat-spinner>
</div>
<div class="login-page-container">
    <div class="login-form-container">
        <form [formGroup]="loginForm" (submit)="onSubmit()" class="login-form">
            <mat-form-field appearance="outline">
                <mat-label>Username</mat-label>
                <input matInput formControlName="username">
                <mat-error 
                    *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)">
                    Username is required
                </mat-error>
            </mat-form-field>
        
            <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput formControlName="password" type="password">
                <mat-error 
                    *ngIf="loginForm.invalid && (loginForm.dirty || loginForm.touched)">
                    Password is required
                </mat-error>
            </mat-form-field>
        
            <button 
                mat-flat-button color="primary" 
                class="login-button"
                [disabled]="loginForm.invalid || loginFormSubmitted"
                >
                    <span *ngIf="!loginFormSubmitted">LOGIN</span>
            </button>
            
            <a class="reset-password" 
                target="_blank" 
                matTooltip="MFA should be enabled for resetting your password"
                matTooltipPosition="after"
                href="{{ resetPasswordUrl }}">Forgot Password?</a>

            <p>OR</p>
                
            <button 
                mat-outlined-button
                class="login-sso-button"
                (click)="onSsoLogin()"
                type="button"
            >
                <span>LOGIN WITH SSO </span>
            </button>
        </form>
    </div>
    
    <div class="lion-graphic-container">
        <img src="assets/lion-graphic.png" alt="lion graphic image" />
    </div>
</div>