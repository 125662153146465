import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { InternsListResponse } from "../models/external/interns-list-response.model";
import { map } from "rxjs";
import { DailyStatusListResponse } from "../models/external/daily-status-list-response.model";
import { ApprovalsListResponse } from "../models/external/approvals-list-response.model";

@Injectable({ providedIn: 'root' })
export class BackendService {
    constructor(
        private http: HttpClient
    ) {}

    getInternsList(year: string, batch: string) {
        const url = this.createUrl(environment.INTERNS_LIST_ENDPOINT, '', year, batch);

        return this.http.get<InternsListResponse>(url);
    }

    getInternDetails(email: string) {
        const url = this.createUrl(environment.GET_INTERN_DETAILS_ENDPOINT, email);

        return this.http.get(url);
    }

    addIntern(internDetails: any) {
        const url = this.createUrl(environment.ADD_INTERN_ENDPOINT);

        return this.http.post(
            url,
            internDetails
        );
    }

    updateIntern(email: string, internDetails: any) {
        const url = this.createUrl(environment.UPDATE_INTERN_DETAILS_ENDPOINT, email);
        
        return this.http.put(
            url,
            internDetails
        );
    }

    deleteIntern(email: string) {
        const url = this.createUrl(environment.DELETE_INTERN_DETAILS_ENDPOINT, email);

        return this.http.delete(url);
    }

    getInternTimeOffSummary(email: string) {
        const url = this.createUrl(environment.INTERN_TIMEOFF_SUMMARY_ENDPOINT, email);

        return this.http.get(url)
        .pipe(
            map((response: any) => response['body'])
        );
    }

    getInternTimeOffById(email: string, timeOffId: string) {
        let url = this.createUrl(environment.INTERN_SINGLE_TIMEOFF_DETAIL, email);
        url = url.replace('{timeOffId}', timeOffId);

        return this.http.get(
            url
        ).pipe(
            map((data: any) => data.body)
        );
    }

    addInternTimeOff(email: string, body: any) {
        const url = this.createUrl(environment.INTERN_ADD_TIMEOFF, email);

        return this.http.post(
            url,
            body
        );
    }

    cancelInternTimeOff(email: string, timeOffId: string) {
        let url = this.createUrl(environment.INTERN_CANCEL_TIMEOFF, email);
        url = url.replace('{timeOffId}', timeOffId);

        return this.http.put(
            url,
            {}
        );
    }

    getDailyStatusList(email: string) {
        const url = this.createUrl(environment.DAILY_STATUS_LIST, email);

        return this.http.get<DailyStatusListResponse>(url);
    }

    addDailyStatus(email: string, body: any) {
        const url = this.createUrl(environment.ADD_DAILY_STATUS, email);

        return this.http.post(
            url,
            body
        );
    }

    getApprovalsList() {
        const url = environment.APPROVALS_LIST;

        return this.http.get<ApprovalsListResponse>(url)
        .pipe(
            map((data: any) => data.body)
        );
    }

    submitApprovalAction(approvalId: string, body: any) {
        let url = environment.APPROVALS_ACTION;
        url = url.replace('{approvalId}', approvalId);

        return this.http.put(
            url,
            body
        );
    }

    getAssessmentListByInternEmail(email: string) {
        const url = this.createUrl(environment.ASSESSMENT_LIST, email);

        return this.http.get(url)
        .pipe(
            map((data: any) => data.body)
        );
    }

    getAssessmentDetailsById(email: string, assessmentId: string) {
        let url = this.createUrl(environment.ASSESSMENT_DETAILS, email);
        url = url.replace('{assessmentId}', assessmentId);

        return this.http.get(url)
        .pipe(
            map((data: any) => data.body)
        );
    }

    addAssessment(email: string, body: any) {
        const url = this.createUrl(environment.ADD_ASSESSMENT, email);

        return this.http.post(
            url,
            body
        );
    }

    deleteAssessment(email: string, assessmentId: string) {
        let url = this.createUrl(environment.DELETE_ASSESSMENT, email);
        url = url.replace('{assessmentId}', assessmentId);

        return this.http.delete(url);
    }

    updateAssessment(email: string, assessmentId: string, body: any) {
        let url = this.createUrl(environment.DELETE_ASSESSMENT, email);
        url = url.replace('{assessmentId}', assessmentId);

        return this.http.put(
            url,
            body
        );
    }
    
    creditTimeOff(email: string, body: any) {
        const url = this.createUrl(environment.CREDIT_TIMEOFF_ENDPOINT, email);

        return this.http.put(
            url,
            body
        );
    }

    getDashboardData(widgetName: string){
        const url = environment.DASHBOARD_ENDPOINT;

        return this.http.get(
            url,
            {
                params: {
                    'widget_name': widgetName
                }
            }
        )
        .pipe(
            map((response: any) => response.body)
        );
    }
    
    getAnnouncementList() {
        const url = environment.LIST_ANNOUNCEMENT;

        return this.http.get<Announcement>(url)
        .pipe(
            map((response: any) => response.body)
        );
    }


    addAnnouncement(body: any) {
        const url = environment.ADD_ANNOUNCEMENT;

        return this.http.post(
            url,
            body
        );
    }

    updateAnnouncement(assessmentId: string, body: any) {
        let url = environment.UPDATE_ANNOUNCEMENT;
        url = url.replace('{announcementId}', assessmentId);

        return this.http.put(
            url,
            body
        );
    }

    deleteAnnouncement(assessmentId: string) {
        let url = environment.DELETE_ANNOUNCEMENT;
        url = url.replace('{announcementId}', assessmentId);

        return this.http.delete(url);
    }

    private createUrl(baseUrl: string, email = '', year = '', batch = '') {
        let url = baseUrl.replace('{year}', year ?? '');
        url = url.replace('{batch}', batch ?? '');
        url = url.replace('{email}', email);

        return url;
    }
}

export interface Announcement {
    ANNOUNCEMENT: string,
    DATA_EXPIRY: string | null,
    EXPIRY_DAYS: string,
    ANNOUNCEMENT_TIME: string,
    TYPE: string,
}